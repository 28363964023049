import React from 'react';
import { useLocation } from 'react-router-dom';

import PageContainer from '../containers/PageContainer';
import SubmissionSuccess from '../components/SubmissionSuccess';

const Submitted = () => {
  const location = useLocation();

  const { numberSaved } = location.state;

  return (
    <PageContainer>
      <SubmissionSuccess estimatedMonthlySavingsInCents={numberSaved} />
    </PageContainer>
  );
};

export default Submitted;
