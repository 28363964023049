import React from 'react';
import { PropTypes } from 'prop-types';
import { Container } from '@mui/material';

import NavBar from '../components/NavBar';

const AppContainer = ({ children }) => {
  return (
    <NavBar>
      <Container>{children}</Container>
    </NavBar>
  );
};

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContainer;
