import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { TableRow, TableCell, Alert } from '@mui/material';
import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const LicenseReviewFormRow = ({ appName, appCost, appLastLogin, pid, ...props }) => {
  const { values, setFieldValue, setFieldError } = useFormikContext();
  if (!values) return null;

  const index = values.values.findIndex((app) => app.participantApplicationID === pid);

  const handleChange = (event) => {
    setFieldValue(`values[${index}].keepApplication`, event.target.value);
    setFieldError(`values[${index}].keepApplication`, 'You must select an option');
  };

  const handleReason = (event) => {
    setFieldValue(`values[${index}].reason`, event.target.value);
  };

  const keepStatus = values.values[index].keepApplication;

  const bgColor = appLastLogin === 'Unknown' ? '#FFF4E5' : '#FFFFFF';

  return (
    <TableRow {...props} sx={{ backgroundColor: `${bgColor}` }}>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Radio
          checked={values.values[index]?.keepApplication === 'true'}
          onChange={handleChange}
          value
          name={`values.values[${index}].keepApplication`}
          inputProps={{ 'aria-label': 'Keep Application' }}
        />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Radio
          checked={values.values[index]?.keepApplication === 'false'}
          onChange={handleChange}
          value={false}
          name={`values.values[${index}].keepApplication`}
          inputProps={{ 'aria-label': 'Remove' }}
        />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Stack spacing={2}>
          <Typography>{appName}</Typography>
          {appName === 'Zoom' && (
            <Alert severity="info">
              Please Note: If you are using the free version of Zoom, you can either keep or remove
              this app and it will not affect your account status. The current information about
              Zoom that shows up on the form is from an older billing model.
            </Alert>
          )}
          <TextField
            size="small"
            label="Reason"
            variant="outlined"
            name={`values.values[${index}].reason`}
            type="text"
            inputProps={{ 'aria-label': 'Reason', minLength: 15 }}
            onChange={handleReason}
            required
            disabled={keepStatus === 'false' || keepStatus === ''}
            helperText={`Please provide a reason if you are keeping this app. (${values.values[index].reason.length} /15)`}
          />
        </Stack>
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Typography
          sx={{
            color: `${keepStatus === 'true' || keepStatus === '' ? '#b2102f' : '#357a38'}`,
            fontWeight: 'bold',
          }}
        >
          {keepStatus === 'true' || keepStatus === '' ? (
            <RemoveIcon sx={{ verticalAlign: 'top' }} />
          ) : (
            <AddIcon sx={{ verticalAlign: 'top' }} />
          )}
          <span>
            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(appCost)}
          </span>
        </Typography>
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        {appLastLogin === 'Unknown' ? (
          <Typography sx={{ color: '#663C00', fontWeight: 'bold' }}>{appLastLogin}</Typography>
        ) : (
          <Typography>{appLastLogin}</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

LicenseReviewFormRow.displayName = 'Review Form';

LicenseReviewFormRow.propTypes = {
  appName: PropTypes.string.isRequired,
  appCost: PropTypes.number.isRequired,
  appLastLogin: PropTypes.string.isRequired,
  pid: PropTypes.number.isRequired,
};

export default LicenseReviewFormRow;
