import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  styled,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

import fileDownload from 'js-file-download';
import { useAPI } from '../providers/APIProvider';
import Error from '../components/Error';
import Loading from '../components/Loading';
import DonutGraph from '../components/DonutGraph';
import EmployeeDetails from '../components/EmployeeDetails';
import ReportSelect from '../components/ReportSelect';
import ApplicationBreakDown from '../components/ApplicationBreakDown';

const POLLING_MS = 5000;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const Reporting = () => {
  const { getCycles, getVerticals, getManagers, getReportByCycle, getPendingUsers } = useAPI();
  const pollingRef = useRef(null);
  const [allCycles, setAllCycles] = useState({ cycles: [] });
  const [allManagers, setAllManagers] = useState({ managers: [] });
  const [allVerticals, setAllVerticals] = useState({ verticals: [] });
  // TODO: remove hard coded default cycle
  const [cycleId, setCycleId] = useState(1);
  const [managerId, setManagerId] = useState();
  const [verticalId, setVerticalId] = useState();
  const [realTimeFlag, setrealTimeFlag] = useState(false);
  const [reportData, setReportData] = useState({ applications: [], enrollment: [] });

  const downloadReportData = async () => {
    const data = await getReportByCycle(cycleId, verticalId, managerId, true);
    fileDownload(data, 'license-data.csv');
  };

  const downloadPendingUsers = async () => {
    const data = await getPendingUsers(cycleId, verticalId, managerId, true);
    fileDownload(data, 'pending-users.csv');
  };

  const handleManagerChange = (event, newValue) => {
    setManagerId(newValue?.employeeId || null);
  };

  const handleVerticalChange = (event, newValue) => {
    setVerticalId(newValue.verticalId);
  };

  const handleCycleChange = (event, newValue) => {
    setCycleId(newValue.cycleId);
  };

  const handleChange = (event) => {
    setrealTimeFlag(event.target.checked);
  };

  // Runs once on initial pageload
  useEffect(() => {
    const getAllManagers = async () => {
      const managerData = await getManagers();
      if (!managerData.managers) return;
      // filter out bad data
      let cleanManagers = managerData.managers.filter((m) => m.employeeId !== '0');
      // remove dups
      cleanManagers = [...new Map(cleanManagers.map((item) => [item.employeeId, item])).values()];
      const managersWithLabel = cleanManagers.map((m) => {
        return { ...m, label: `${m.firstName} ${m.lastName}` };
      });
      // add option for null manager
      managersWithLabel.push({
        employeeId: null,
        firstName: '',
        lastName: '',
        label: '',
      });
      managerData.managers = managersWithLabel;

      setAllManagers(managerData);
      setManagerId(managerData.defaultOption);
    };

    const getAllCycles = async () => {
      const cycleData = await getCycles();
      if (!cycleData.cycles) return;
      setAllCycles(cycleData);
      setCycleId(Number(cycleData.defaultOption));
    };

    // TODO: ask backend team to fix struct
    const getAllVerticals = async () => {
      const verticalData = await getVerticals();
      if (!verticalData.cycles) return;
      // API sending back cycles instead of verticals, fix it
      setAllVerticals({
        defaultOption: verticalData.defaultOption,
        verticals: verticalData.cycles,
      });
      setVerticalId(verticalData.defaultOption);
    };

    const initPage = async () => {
      await getAllCycles();
      await getAllManagers();
      await getAllVerticals();
    };

    initPage();
  }, [getCycles, getVerticals, getManagers]);

  useEffect(() => {
    const getReportingData = async (cId, vId, mId) => {
      const rData = await getReportByCycle(cId, vId, mId);
      setReportData(rData);
    };

    const pollReportingData = async () => {
      await getReportingData(cycleId, verticalId, managerId);

      pollingRef.current = setTimeout(() => {
        pollReportingData();
      }, POLLING_MS);
    };

    if (realTimeFlag) {
      pollReportingData();
    }

    clearTimeout(pollingRef.current);
    getReportingData(cycleId, verticalId, managerId);
  }, [cycleId, verticalId, managerId, realTimeFlag, getReportByCycle]);

  // TODO: This conditional is ugly, I know.
  // This page needs to be abstracted out more so that can conditionally render each drop down.
  // But until then, this will do.
  if (
    allManagers.managers?.length <= 0 ||
    allVerticals.verticals?.length <= 0 ||
    allCycles.cycles?.length <= 0 ||
    !reportData ||
    reportData.applications?.length <= 0 ||
    reportData.enrollment?.length <= 0
  ) {
    return <Loading />;
  }

  if (reportData?.error) {
    return <Error message="Oops! Having trouble fetching report data" />;
  }

  return (
    <Box sx={{ width: '100%', margin: '1rem auto', padding: '1rem' }}>
      <Stack spacing={5}>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          mt={10}
          spacing={5}
        >
          <DonutGraph
            totalEnrollments={reportData.enrollment.totalEnrolled}
            completedEnrollments={reportData.enrollment.completedReviews}
            totalApplications={reportData.applications.total}
            keptApplications={reportData.applications.kept}
            removedApplications={reportData.applications.removed}
            percentSaved={reportData.applications.percentSaved}
          />
          <Stack spacing={2} alignItems="baseline">
            <ReportSelect
              allCycles={allCycles}
              allManagers={allManagers}
              allVerticals={allVerticals}
              cycleId={cycleId}
              managerId={managerId}
              verticalId={verticalId}
              handleCycleChange={handleCycleChange}
              handleManagerChange={handleManagerChange}
              handleVerticalChange={handleVerticalChange}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={realTimeFlag}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'real time flag' }}
                  />
                }
                label="Real Time Reporting"
              />
            </FormGroup>
            <Button type="submit" variant="contained" onClick={downloadReportData}>
              Download Data
            </Button>
          </Stack>
        </Stack>
        {reportData.applications.applicationDetail?.length > 0 && (
          <Grid item xs={12} style={{ marginBottom: '3rem' }}>
            <Typography
              component="h2"
              sx={{ fontWeight: 600, fontSize: '1.4rem', marginBottom: '1rem' }}
            >
              Application Breakdown
            </Typography>
            <Item>
              <ApplicationBreakDown
                applicationDetails={reportData.applications.applicationDetail}
              />
            </Item>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
              <Typography
                component="h2"
                sx={{ fontWeight: 600, fontSize: '1.4rem', marginBottom: '1rem' }}
              >
                Estimated Yearly Savings
              </Typography>

              <Typography
                component="h2"
                sx={{ fontWeight: 600, fontSize: '1.4rem', marginBottom: '1rem' }}
              >
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(reportData.applications.estimatedSavings * 12)}
              </Typography>
            </Stack>
          </Grid>
        )}
        {reportData.enrollment.pendingUsers?.length > 0 && (
          <Grid item xs={12}>
            <Stack justifyContent="space-between" direction="row" alignItems="center">
              <Typography
                component="h2"
                sx={{ fontWeight: 600, fontSize: '1.4rem', marginBottom: '1rem' }}
              >
                Pending License Reviews
              </Typography>

              <Button type="submit" variant="contained" onClick={downloadPendingUsers}>
                Pending Users
              </Button>
            </Stack>

            <Item>
              <EmployeeDetails pendingEmployees={reportData.enrollment.pendingUsers} />
            </Item>
          </Grid>
        )}
      </Stack>
    </Box>
  );
};

export default Reporting;
