import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

import Loading from '../components/Loading';

const AuthProvider = ({ children }) => {
  const { error, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  if (isLoading) return <Loading />;

  if (!isAuthenticated && !error) {
    loginWithRedirect();
    return null;
  }

  return children;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
