import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  TableContainer,
} from '@mui/material';
import ManagerOverrideForm from './ManagerOverrideForm';

const EmployeeDetails = ({ pendingEmployees }) => {
  if (pendingEmployees.length <= 0) {
    return <div>No data at this time.</div>;
  }

  return (
    <TableContainer sx={{ maxHeight: '500px' }}>
      <Table stickyHeader aria-label="employee details" size="small" sx={{ height: 'max-content' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography sx={{ fontWeight: 'bold' }}>Employee ID</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 'bold' }}>Name</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 'bold' }}>Job Title</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 'bold' }}>Vertical</Typography>
            </TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 'bold' }}>Remove from Cycle</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingEmployees.map((row) => (
            <TableRow key={row.employeeId}>
              <TableCell>{row.employeeId}</TableCell>
              <TableCell>
                {row.firstName} {row.lastName}
              </TableCell>
              <TableCell>{row.jobTitle}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.vertical}</TableCell>
              <TableCell>
                {row.isDownline && (
                  <ManagerOverrideForm
                    employeename={`${row.firstName} ${row.lastName}`}
                    participantId={row.participantId}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

EmployeeDetails.propTypes = {
  pendingEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      employeeId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      vertical: PropTypes.string,
      participantId: PropTypes.number,
    })
  ),
};

EmployeeDetails.defaultProps = {
  pendingEmployees: [],
};

export default EmployeeDetails;
