import React from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';

import { useUser } from './providers/UserProvider';
import AppContainer from './containers/AppContainer';
import Licenses from './pages/Licenses';
import Reporting from './pages/Reporting';
import Reviews from './pages/Reviews';
import Submitted from './pages/Submitted';
import TeamLicenses from './pages/TeamLicenses';
import Status from './pages/Status';

const Router = () => {
  const { userAccess } = useUser();

  const { isTechLead, isPresident } = userAccess;

  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route path="*" element={<Navigate to="/licenses" replace />} />
          <Route path="/licenses" element={<Licenses />} />
          <Route path="/submitted" element={<Submitted />} />
          <Route path="/reporting" element={<Reporting />} />
          {isTechLead && <Route path="/team-licenses" element={<TeamLicenses />} />}
          {isPresident && <Route path="/reviews" element={<Reviews />} />}
          <Route path="/status" element={<Status />} />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
};

export default Router;
