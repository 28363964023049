import React, { useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red, grey } from '@mui/material/colors';

import Router from './Router';
import APIProvider from './providers/APIProvider';
import AuthProvider from './providers/AuthProvider';
import UserProvider from './providers/UserProvider';

const cohesionWriteKey = process.env.REACT_APP_COHESION_WRITEKEY;
const cohesionSourceKey = process.env.REACT_APP_COHESION_SOURCEKEY;

const theme = createTheme({
  palette: {
    primary: {
      main: red[900],
    },
    secondary: {
      main: grey[500],
    },
  },
});

const App = () => {
  // Appending Cohesion integration script
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = '.preampjs [data-preamp], .fusejs [data-fuse] {opacity: 0 !important}';

    const script = document.createElement('script');
    script.innerHTML = `!function(co,h,e,s,i,o,n){var d='documentElement';var a='className';h[d][a]+=' preampjs fusejs';
    n.k=e;co._Cohesion=n;co._Preamp={k:s,start:new Date};co._Fuse={k:i};co._Tagular={k:o};
    [e,s,i,o].map(function(x){co[x]=co[x]||function(){(co[x].q=co[x].q||[]).push([].slice.call(arguments))}});
    h.addEventListener('DOMContentLoaded',function(){co.setTimeout(function(){
    var u=h[d][a];h[d][a]=u.replace(/ ?preampjs| ?fusejs/g,'')},3e3);
    co._Preamp.docReady=co._Fuse.docReady=!0});var z=h.createElement('script');
    z.async=1;z.src='https://cdn.cohesionapps.com/cohesion/cohesion-latest.min.js';h.head.appendChild(z);}
    (window,document,'cohesion','preamp','fuse','tagular',{
        tagular:{writeKey:'${cohesionWriteKey}', sourceKey:'${cohesionSourceKey}'}
    })`;

    if (cohesionWriteKey && cohesionSourceKey) {
      document.body.appendChild(style);
      document.body.appendChild(script);
    }
  }, []);

  return (
    <Auth0Provider
      domain="redventures-prod.auth0.com"
      clientId="kK2Q3jHpPfSlDPnBJ6VVx5EkoT50ALgP"
      redirectUri={window.location.origin}
      connection="okta"
      audience="production-software-license-review-resource"
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <AuthProvider>
        <APIProvider>
          <UserProvider>
            <ThemeProvider theme={theme}>
              <Router />
            </ThemeProvider>
          </UserProvider>
        </APIProvider>
      </AuthProvider>
    </Auth0Provider>
  );
};

export default App;
