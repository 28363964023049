import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

import Donut from './Donut';

const DonutGraph = ({
  totalEnrollments,
  completedEnrollments,
  totalApplications,
  keptApplications,
  removedApplications,
  percentSaved,
}) => {
  const percentage = (partialValue, totalValue) => {
    const perc = (100 * partialValue) / totalValue;
    if (Number.isNaN(perc)) {
      return 0;
    }
    return Math.round(perc);
  };

  const enrollPerc = percentage(completedEnrollments, totalEnrollments);
  const appPerc = percentage(keptApplications + removedApplications, totalApplications);
  const removedPerc = percentage(removedApplications, totalApplications);

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }}>
      <Donut percentage={enrollPerc} color="#FFB10A" bgColor="#FFF2D6">
        <Typography component="p" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
          {completedEnrollments} / {totalEnrollments}
        </Typography>
        <Typography component="p" sx={{ fontSize: '0.9rem' }}>
          Enrollments Completed
        </Typography>
      </Donut>
      <Donut percentage={appPerc} color="#DE2B2B" bgColor="#F7CACA">
        <Typography component="p" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
          {keptApplications + removedApplications} / {totalApplications}
        </Typography>
        <Typography component="p" sx={{ fontSize: '0.9rem' }}>
          Applications Reviewed
        </Typography>
      </Donut>
      <Donut percentage={removedPerc} color="#16D9F3" bgColor="#C5F6FC">
        <Typography component="p" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
          {removedPerc}%
        </Typography>
        <Typography component="p" sx={{ fontSize: '0.9rem' }}>
          Applications Removed
        </Typography>
      </Donut>
      <Donut percentage={Math.round(percentSaved * 100)} color="#39D070" bgColor="#CDF3DB">
        <Typography component="p" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
          {Math.round(percentSaved * 100)}%
        </Typography>
        <Typography component="p" sx={{ fontSize: '0.9rem' }}>
          Savings
        </Typography>
      </Donut>
    </Stack>
  );
};

DonutGraph.propTypes = {
  totalEnrollments: PropTypes.number,
  completedEnrollments: PropTypes.number,
  totalApplications: PropTypes.number,
  keptApplications: PropTypes.number,
  removedApplications: PropTypes.number,
  percentSaved: PropTypes.number,
};

DonutGraph.defaultProps = {
  totalEnrollments: null,
  completedEnrollments: null,
  totalApplications: null,
  keptApplications: null,
  removedApplications: null,
  percentSaved: null,
};

export default DonutGraph;
