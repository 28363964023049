import React from 'react';
import PropTypes from 'prop-types';

const Donut = ({ percentage, color, bgColor, children }) => {
  let valuePerc = percentage;

  if (percentage >= 1 && percentage <= 100) {
    valuePerc = percentage / 100;
  }

  if (valuePerc < 0 || valuePerc > 1) {
    console.error('Invalid percentage provided');
    return null;
  }

  const valueDeg = (360 - 360 * valuePerc).toFixed(0);

  return (
    <div
      style={{
        margin: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '180px',
        height: '180px',
        borderRadius: '50%',
        background: `conic-gradient(${bgColor} 0deg ${valueDeg}deg, ${color} ${valueDeg}deg 360deg)`,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '130px',
          height: '130px',
          borderRadius: '50%',
          backgroundColor: '#FFFFFF',
        }}
      >
        <div
          style={{
            padding: '12px',
            textAlign: 'center',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Donut.defaultProps = {
  children: null,
};

Donut.propTypes = {
  children: PropTypes.node,
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};

export default Donut;
