import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { Typography } from '@mui/material';

const SubmissionSuccess = ({ estimatedMonthlySavingsInCents }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginRight: 24,
          }}
        >
          <CheckCircleOutlineRoundedIcon style={{ color: 'green', fontSize: 96 }} />
        </div>
        <div>
          <Typography
            variant="h1"
            sx={{
              fontSize: '3rem',
            }}
          >
            Submission Successful!
          </Typography>
        </div>
      </div>
      <Typography sx={{ marginTop: '1rem' }}>
        Thank you for submitting your application review, your answers have been logged and if you
        opted to remove any applications associated with your account, you will receive Service Now
        email updating you on the status.
      </Typography>
      {estimatedMonthlySavingsInCents !== '$0.00' && (
        <Typography sx={{ marginTop: '2rem', fontSize: '1.5rem' }}>
          Based on your submission, you’ve saved an estimated{' '}
          <span style={{ fontWeight: 600, color: 'green' }}>{estimatedMonthlySavingsInCents}</span>{' '}
          per month!
        </Typography>
      )}
    </div>
  );
};

SubmissionSuccess.defaultProps = {
  estimatedMonthlySavingsInCents: 0,
};

SubmissionSuccess.propTypes = {
  estimatedMonthlySavingsInCents: PropTypes.string,
};

export default SubmissionSuccess;
