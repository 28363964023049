import React from 'react';
import { PropTypes } from 'prop-types';
import { Container, Box } from '@mui/material';

const PageContainer = ({ children }) => {
  return (
    <Container maxWidth="lg">
      <Box textAlign="left" margin={1} padding={1}>
        {children}
      </Box>
    </Container>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;
