import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FeedbackOutlined from '@mui/icons-material/FeedbackOutlined';

import { useUser } from '../providers/UserProvider';

const SLACK_FEEDBACK_URL = 'https://redventures.slack.com/archives/C047RK99TFT';

const NavBar = ({ children }) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const { userAccess } = useUser();
  const { isTechLead, isPresident } = userAccess;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (targetRoute) => () => {
    setAnchorElNav(null);
    navigate(targetRoute);
  };

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 8,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 600,
                color: 'inherit',
                textDecoration: 'none',
                fontFamily: 'Rockwell',
              }}
            >
              RED|VENTURES
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {/* mobile nav */}
                <MenuItem onClick={handleNavigate('/licenses')}>
                  <Typography textAlign="center">Licenses</Typography>
                </MenuItem>
                {isTechLead && (
                  <MenuItem onClick={handleNavigate('/team-licenses')}>
                    <Typography textAlign="center">Industry Group Spend</Typography>
                  </MenuItem>
                )}
                {isPresident && (
                  <MenuItem onClick={handleNavigate('/reviews')}>
                    <Typography textAlign="center">Reviews</Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={handleNavigate('/reporting')}>
                  <Typography textAlign="center">Reporting</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontWeight: 600,
                color: 'inherit',
                textDecoration: 'none',
                fontFamily: 'Rockwell',
              }}
            >
              RED|VENTURES
            </Typography>
            <Box sx={{ flexEnd: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="Report Feedback"
                href={SLACK_FEEDBACK_URL}
                color="inherit"
              >
                <FeedbackOutlined />
              </IconButton>
            </Box>
            {/* desktop nav */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="View your current software licenses">
                <Button
                  onClick={handleNavigate('/licenses')}
                  sx={{ my: 2, mr: 2, color: 'white', display: 'block' }}
                >
                  Licenses
                </Button>
              </Tooltip>
              {isTechLead && (
                <Tooltip title="View software licenses for your team">
                  <Button
                    onClick={handleNavigate('/team-licenses')}
                    sx={{ my: 2, mr: 2, color: 'white', display: 'block' }}
                  >
                    Industry Group Spend
                  </Button>
                </Tooltip>
              )}
              {isPresident && (
                <Tooltip title="Review team software license submissions">
                  <Button
                    onClick={handleNavigate('/reviews')}
                    sx={{ my: 2, mr: 2, color: 'white', display: 'block' }}
                  >
                    Reviews
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="View reporting data">
                <Button
                  onClick={handleNavigate('/reporting')}
                  sx={{ my: 2, mr: 2, color: 'white', display: 'block' }}
                >
                  Reporting
                </Button>
              </Tooltip>
            </Box>
            <Box sx={{ flexEnd: 1, display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="Report Feedback">
                <IconButton
                  size="large"
                  aria-label="Report Feedback"
                  href={SLACK_FEEDBACK_URL}
                  color="inherit"
                >
                  <FeedbackOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </>
  );
};

NavBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavBar;
