import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@mui/material';
import fileDownload from 'js-file-download';
import PageContainer from '../containers/PageContainer';
import { useAPI } from '../providers/APIProvider';
import Loading from '../components/Loading';

const Status = () => {
  const { getCycleStatus, getConsumptionCycleStatusDownload } = useAPI();
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const downloadConsumptionCycleStatus = async () => {
    const data = await getConsumptionCycleStatusDownload();
    fileDownload(data, 'consumption-cycle-status-report.csv');
  };

  useEffect(() => {
    const initData = async () => {
      setTableData(await getCycleStatus());
      setIsLoading(false);
    };

    initData();
  }, [getCycleStatus]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageContainer maxWidth="lg">
      <TableContainer sx={{ marginY: '6rem' }}>
        <Table
          stickyHeader
          aria-label="status data table"
          size="small"
          sx={{ height: 'max-content' }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ verticalAlign: 'top' }} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>Industry Group</Typography>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>Tech Lead</Typography>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>President</Typography>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>Completed Date</Typography>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>Status</Typography>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>Pending IT Lead</Typography>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>IT Lead Percent Remaining</Typography>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>Pending Business Lead</Typography>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }} align="center">
                <Typography sx={{ fontWeight: 'bold' }}>Total Charges</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((i, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell align="center">{i.industryGroup}</TableCell>
                    <TableCell align="center">{i.techLead}</TableCell>
                    <TableCell align="center">{i.president}</TableCell>
                    <TableCell align="center">{i.completedDateTime}</TableCell>
                    <TableCell align="center">{i.status}</TableCell>
                    <TableCell align="center">{i.pendingITLead}</TableCell>
                    <TableCell align="center">{i.itLeadPercentRemaining}</TableCell>
                    <TableCell align="center">{i.pendingBizLead}</TableCell>
                    <TableCell align="center">{i.totalCharges}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        sx={{ margin: '1rem' }}
        type="submit"
        variant="contained"
        onClick={downloadConsumptionCycleStatus}
      >
        Download this Data
      </Button>
    </PageContainer>
  );
};

export default Status;
