import React, { useEffect, useState } from 'react';
import { Alert, Link, Typography } from '@mui/material';
import { useAPI } from '../providers/APIProvider';
import PageContainer from '../containers/PageContainer';
import Loading from '../components/Loading';
import LicenseReviewForm from '../components/LicenseReviewForm';
import LicenseReviewAlert, { shouldShowAlert } from '../components/LicenseReviewAlert';
import { useUser } from '../providers/UserProvider';

const Licenses = () => {
  // State for the applications to be displayed
  const [user, setUserData] = useState({ applications: [] });
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { getUserApplications, submitLicenseReview } = useAPI();

  const { userAccess } = useUser();
  const { vertical, inCycle, upcomingCycles } = userAccess;

  useEffect(() => {
    const initData = async () => {
      const userData = await getUserApplications();
      setUserData(userData);
      setIsLoading(false);
    };
    initData();
  }, [getUserApplications]);

  useEffect(() => {
    setShowAlert(shouldShowAlert(user, inCycle));
  }, [user, inCycle]);

  if (isLoading) {
    return <Loading />;
  }

  if (showAlert) {
    return (
      <PageContainer>
        <LicenseReviewAlert user={user} />
      </PageContainer>
    );
  }

  // Remove duplicate applications from the array - fixes validation issues
  const removeDuplicate = user.applications.reduce((acc, current) => {
    if (!acc.some((app) => app.participantApplicationID === current.participantApplicationID)) {
      acc.push(current);
    }
    return acc;
  }, []);

  return (
    <PageContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Welcome, {user.firstName}!
      </Typography>
      <Typography variant="body1" paragraph gutterBottom>
        Below you will find software associated with your account and the cost allocated back to
        your business each month.
      </Typography>
      <Typography variant="body1" paragraph gutterBottom>
        Review the list and think about how often you use each tool and if it is essential to your
        current job role. If you no longer need the software, select the option to remove it from
        your account. If you decide you need the software, please enter a short reason why you need
        this software, such as “I use this daily for my job” or “This app allows me to be more
        productive while working from home”.
      </Typography>
      <Typography variant="body1" paragraph gutterBottom>
        Once you’ve made your decision for each application listed below, submit the form and any
        removals will be processed on your behalf. There is no further action needed by you.
      </Typography>
      <Typography variant="body1" paragraph gutterBottom>
        This form must be completed by {upcomingCycles[0].endDate}. If you do not complete the form
        by the cycle end date all applications listed will be removed from your account.
      </Typography>
      <Typography variant="body1" paragraph gutterBottom>
        If you have any questions, please include them in the{' '}
        <Link
          href={
            vertical === 'Health'
              ? 'https://rvohealth.slack.com/archives/C04G9TF9A3S'
              : 'https://redventures.slack.com/archives/C047RK99TFT'
          }
          underline="hover"
        >
          #sw-license-review
        </Link>{' '}
        channel.
      </Typography>
      <Alert severity="warning">
        Below you will find apps highlighted that do not have a last login date.{' '}
        <strong>
          Please note that the Last Login for each application indicates the last time you
          authenticated with Okta to access the application.
        </strong>{' '}
        If you have not logged into or used the application in the past 90 days we recommend you
        examine if this application is needed. To update the last login date, you can log into your
        Okta account and click on the tile for that application.
      </Alert>
      <LicenseReviewForm
        applications={removeDuplicate}
        participantID={user.participantID}
        submitLicenseReview={submitLicenseReview}
      />
    </PageContainer>
  );
};

export default Licenses;
