import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Stack,
  Box,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import StackedBarGraph from './StackedBarGraph';

const ApplicationBreakDown = ({ applicationDetails, ...props }) => {
  return (
    <>
      {applicationDetails.length > 0 && (
        <TableContainer sx={{ height: '500px' }} {...props}>
          <Table
            stickyHeader
            aria-label="individual appliction breakdown"
            size="small"
            sx={{ height: 'max-content' }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '20%', verticalAlign: 'top' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>Application Name</Typography>
                </TableCell>
                <TableCell align="center" sx={{ width: '60%', verticalAlign: 'top' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>Progress</Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ justifyContent: 'center', margin: '.5rem 0' }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CircleIcon
                        sx={{
                          color: '#FFE099',
                          width: '1rem',
                          height: '1rem',
                          marginRight: '0.5rem',
                        }}
                      />
                      <Typography variant="body2">Pending Users</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CircleIcon
                        sx={{
                          color: '#B3D1E6',
                          width: '1rem',
                          height: '1rem',
                          marginRight: '0.5rem',
                        }}
                      />
                      <Typography variant="body2">Kept Applications</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CircleIcon
                        sx={{
                          color: '#49D47C',
                          width: '1rem',
                          height: '1rem',
                          marginRight: '0.5rem',
                        }}
                      />
                      <Typography variant="body2">Removed Applications</Typography>
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell align="right" sx={{ width: '20%', verticalAlign: 'top' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>Estimated Savings</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applicationDetails
                .sort((a, b) => (a.EstimatedSavings > b.EstimatedSavings ? -1 : 1))
                .map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <StackedBarGraph
                        pending={row.pending}
                        kept={row.kept}
                        removed={row.removed}
                        total={row.total}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(row.EstimatedSavings)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {applicationDetails.length === 0 && (
        <Box sx={{ margin: '1rem', backgroundColor: '#EEEEEE' }}>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            No data available at this time.
          </Typography>
        </Box>
      )}
    </>
  );
};

ApplicationBreakDown.propTypes = {
  applicationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      EstimatedSavings: PropTypes.number,
      kept: PropTypes.number,
      name: PropTypes.string,
      pending: PropTypes.number,
      removed: PropTypes.number,
      total: PropTypes.number,
    })
  ).isRequired,
};

export default ApplicationBreakDown;
