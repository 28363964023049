import React, { useEffect, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { useAPI } from './APIProvider';

export const UserContext = React.createContext();
export const useUser = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const { isAuthenticated, user } = useAuth0();
  const [userAccess, setUserAccess] = useState({
    licenses: true,
    consumption: false,
    employeeType: 'user',
    isPresident: false,
    isTechLead: false,
  });

  const { getUserAccess } = useAPI();

  useEffect(() => {
    const getAccessData = async () => {
      const accessData = await getUserAccess();
      setUserAccess({
        ...accessData.access,
        ...accessData.cycle,
        isPresident:
          accessData.access.consumption && accessData.access.employeeType === 'president',
        isTechLead: accessData.access.consumption && accessData.access.employeeType === 'itlead',
      });
    };
    // if not authed, keep it moving
    if (!isAuthenticated) return;
    getAccessData();
  }, [isAuthenticated, getUserAccess, user]);
  return <UserContext.Provider value={{ userAccess }}>{children}</UserContext.Provider>;
};

UserProvider.defaultProps = {
  children: null,
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export default UserProvider;
