import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { useAPI } from '../providers/APIProvider';

const ManagerOverrideForm = ({ employeename, participantId }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const { sendManagerOverride } = useAPI();

  const sendOverrideSubmit = async (values) => {
    const submitResp = await sendManagerOverride(values);
    if (submitResp.error) {
      setError(true);
      return;
    }
    setOpen(false);
  };
  return (
    <div>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Remove from cycle
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Formik
          initialValues={{
            participantId,
            reason: '',
          }}
          onSubmit={sendOverrideSubmit}
        >
          {({ handleSubmit, isSubmitting, handleChange, values }) => (
            <Form onSubmit={handleSubmit}>
              <DialogTitle>Remove {employeename} from cycle</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Typography variant="body1" paragraph gutterBottom>
                    By submitting this form you will be taking {employeename} out of this license
                    review cycle.
                  </Typography>
                  <Typography variant="body1" paragraph gutterBottom>
                    This will allow them to keep all their apps and no other action will be needed
                    for this cycle. This action will be logged and reasons provided for overrides
                    will be public. Please make sure not to include any private information in the
                    reason field.
                  </Typography>
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  name="reason"
                  label="Reason for removing participant from cycle"
                  type="text"
                  fullWidth
                  variant="standard"
                  multiline
                  value={values.reason}
                  helperText="Minimum 20 characters"
                  onChange={handleChange}
                  required
                  inputProps={{
                    'aria-label': 'Reason for removing participant from cycle',
                    minLength: 20,
                  }}
                />
              </DialogContent>
              {isSubmitting && <Alert severity="success">Success!</Alert>}
              {error && <Alert severity="error">Error!</Alert>}
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button type="submit" variant="contained" disabled={isSubmitting}>
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

ManagerOverrideForm.propTypes = {
  employeename: PropTypes.string.isRequired,
  participantId: PropTypes.number.isRequired,
};

export default ManagerOverrideForm;
