import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, AlertTitle, Link, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useUser } from '../providers/UserProvider';

export const shouldShowAlert = (user, inCycle) => {
  return (
    // there is no active cycle
    !inCycle ||
    // no user data
    !user ||
    user.error ||
    // not in an active cycle
    !user.applications ||
    // no apps
    user.applications.length <= 0 ||
    // no active cycle for user
    !user.cycle_active ||
    // form already submitted for current cycle
    user.complete_date !== null
  );
};

const LicenseReviewAlert = (props) => {
  const { user } = props;
  const [message, setMessage] = useState();
  const { userAccess } = useUser();
  const { vertical, inCycle, upcomingCycles } = userAccess;

  useEffect(() => {
    if (!inCycle) {
      let body = `We currently do not have an active cycle.`;
      if (upcomingCycles?.length > 0) {
        body += ` The next cycle will start on ${format(
          new Date(upcomingCycles[0].startDate),
          'PP'
        )} and end on ${format(
          new Date(upcomingCycles[0].endDate),
          'PP'
        )}. Please check back then.`;
      }

      setMessage({
        title: 'Hi! Welcome to the Optional Software License Review Tool',
        severity: 'info',
        body,
      });
      return;
    }
    if (!user || user.error) {
      setMessage({
        title: 'Oh No!',
        severity: 'error',
        body: 'We are having trouble getting your license data',
      });
      return;
    }
    if (!user.applications || user.applications.length <= 0) {
      setMessage({
        title: 'No Applications',
        severity: 'info',
        body: 'You have no applications to be reviewed at this time',
      });
      return;
    }
    if (!user.cycle_active) {
      setMessage({
        title: `Hi ${user.firstName}!`,
        severity: 'info',
        body: 'At this time you do not need to review your optional software licenses. You will be notified when a new cycle has started.',
      });
    }
    if (user.complete_date !== null) {
      setMessage({
        title: `Hi ${user.firstName}!`,
        severity: 'info',
        body: 'Thank you for reviewing your optional software licenses. We have recieved your information for this cycle. You will be notified when a new cycle has started.',
      });
    }
  }, [user, upcomingCycles, inCycle]);

  if (!message) return null;
  return (
    <Alert severity={message.severity}>
      <AlertTitle>{message.title}</AlertTitle>
      <Typography variant="body2">{message.body}</Typography>
      <Typography variant="body2">
        If you have questions please visit the{' '}
        <Link
          href={
            vertical === 'Health'
              ? 'https://rvohealth.slack.com/archives/C04G9TF9A3S'
              : 'https://redventures.slack.com/archives/C047RK99TFT'
          }
          underline="hover"
        >
          {` #sw-license-review `}
        </Link>{' '}
        channel in Slack. Thank you.
      </Typography>
    </Alert>
  );
};

LicenseReviewAlert.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default LicenseReviewAlert;
