import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Paper } from '@mui/material';

const StackedBarGraph = ({ pending, kept, removed, total }) => {
  const percentage = (partialValue, totalValue) => {
    const result = (partialValue / totalValue) * 100;
    return Math.round(result);
  };

  return (
    <Stack
      sx={{ width: '100%', margin: '1rem', backgroundColor: '#EEEEEE' }}
      component={Paper}
      direction="row"
      spacing={0}
    >
      {percentage(pending, total) > 0 && (
        <div
          style={{
            width: `${percentage(pending, total)}%`,
            backgroundColor: '#FFF6E1',
            padding: '.5rem',
          }}
        >
          <Typography variant="body2">{pending}</Typography>
        </div>
      )}
      {percentage(kept, total) > 0 && (
        <div
          style={{
            width: `${percentage(kept, total)}%`,
            backgroundColor: '#E1EDF5',
            padding: '.5rem',
          }}
        >
          <Typography variant="body2">{kept}</Typography>
        </div>
      )}
      {percentage(removed, total) > 0 && (
        <div
          style={{
            width: `${percentage(removed, total)}%`,
            backgroundColor: '#49D47C',
            padding: '.5rem',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            {removed}
          </Typography>
        </div>
      )}
    </Stack>
  );
};

StackedBarGraph.propTypes = {
  pending: PropTypes.number.isRequired,
  kept: PropTypes.number.isRequired,
  removed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default StackedBarGraph;
