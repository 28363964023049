import React, { useState, useEffect } from 'react';
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Container,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Formik, Form } from 'formik';
import fileDownload from 'js-file-download';

import TeamLicensesTable from '../components/TeamLicensesTable';
import TeamAppReviewForm from '../components/TeamAppReviewForm';
import { useAPI } from '../providers/APIProvider';
import Loading from '../components/Loading';

const TeamLicenses = () => {
  const { getTeamLicenses, saveSupplierReview, getConsumptionDownload } = useAPI();
  const [teamLicenses, setTeamLicences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState({});
  const [shouldReload, setShouldReload] = useState(null);

  useEffect(() => {
    const initData = async () => {
      const { industryGroups } = await getTeamLicenses();
      setTeamLicences(industryGroups);
      setIsLoading(false);

      if (shouldReload === null) {
        industryGroups.forEach((vertical) =>
          vertical.spendCategories?.forEach((sc) =>
            sc.suppliers?.forEach((supplier) =>
              setIsOpen((prevState) => {
                return {
                  ...prevState,
                  [supplier.consumptionChargeId]: !supplier.isComplete,
                };
              })
            )
          )
        );
        setShouldReload(true);
      }
    };
    if (shouldReload || shouldReload === null) initData();
    setShouldReload(false);
  }, [getTeamLicenses, shouldReload]);

  if (isLoading) {
    return <Loading />;
  }

  const downloadReportData = async () => {
    const data = await getConsumptionDownload();
    fileDownload(data, 'license-data.csv');
  };

  const defaultValues = (supplier) => {
    return {
      allocationId: supplier.consumptionChargeId,
      actionId: supplier.actionId == null ? '' : supplier.actionId,
      teamLeadNotes: supplier.itLeadNotes,
      showAlert: false,
    };
  };

  const handleSave = async (values) => {
    const response = await saveSupplierReview(values, false);
    if (response) {
      console.log('Save Successful');
    }

    setShouldReload(true);
  };

  const handleChange = (id, values, touched) => {
    if (isOpen[id]) {
      // Making sure it only saves if they haven't already completed the form
      if (Object.keys(touched).length > 0 && !values.values.showAlert) {
        handleSave(values);
      }
    }

    setIsOpen((prevState) => {
      return {
        ...prevState,
        [id]: !prevState[id],
      };
    });
  };

  const sendToPresident = async (values, formikBag) => {
    const response = await saveSupplierReview(values, true);
    if (response) {
      formikBag.setFieldValue('values.showAlert', true);
    }
    setShouldReload(true);
  };

  return (
    <Container maxWidth="lg">
      <Typography sx={{ marginTop: '6rem' }} variant="body1">
        Below you will find the monthly Direct Spend (via Workday) and Consumption Based (via
        Apptio) SaaS costs associated with your industry group. For each contract listed below,
        please use the dropdown box to choose from the four usage recommendation options going
        forward. Be sure to provide reasoning for each disposition. “Save” will ensure your notes
        and selections remain if you were to leave or refresh the page. As you complete each
        contract recommendation, click “Submit.” Once all contracts have been reviewed, your
        recommendations will be sent to your industry leader for final approval.
      </Typography>
      <Typography sx={{ margin: '2rem auto' }} variant="body1">
        If you have any questions, feel free to reach out to{' '}
        <Link href="https://redventures.slack.com/archives/C04F99DA6NL">
          #procurement-saas-support
        </Link>
        .
      </Typography>
      <Paper elevate={4} sx={{ margin: '6rem auto', padding: '2rem' }}>
        <Button variant="contained" onClick={downloadReportData}>
          Download Data
        </Button>
        {teamLicenses.map((teamLicense) =>
          teamLicense.spendCategories?.map((spendCategory) => (
            <TeamLicensesTable
              key={spendCategory.chargeType}
              label={spendCategory.chargeType}
              monthlyCharges={spendCategory.spendSummary.monthly}
              total={spendCategory.spendSummary.totalSpend}
              industry={teamLicense.industryGroup}
            >
              {spendCategory.suppliers?.map((supplier) => {
                return (
                  <Formik
                    key={supplier.consumptionChargeId}
                    initialValues={{
                      values: defaultValues(supplier),
                    }}
                    onSubmit={sendToPresident}
                  >
                    {({ handleSubmit, values, touched }) => (
                      <Form onSubmit={handleSubmit}>
                        <Accordion
                          sx={{ margin: '1rem' }}
                          expanded={isOpen[supplier.consumptionChargeId]}
                          onChange={() =>
                            handleChange(supplier.consumptionChargeId, values, touched)
                          }
                        >
                          <AccordionSummary
                            sx={{
                              backgroundColor: '#FAFAFA',
                              '& .MuiAccordionSummary-content': { justifyContent: 'space-between' },
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${supplier.consumptionChargeId}-content`}
                            id={`panel${supplier.consumptionChargeId}-header`}
                          >
                            <Stack direction="row" spacing={2}>
                              {supplier.isComplete && (
                                <Chip
                                  sx={{ borderRadius: '.5rem' }}
                                  icon={<CheckCircleIcon />}
                                  label="Done"
                                  color="success"
                                  variant="outlined"
                                />
                              )}
                              <Typography sx={{ fontWeight: 'bold', alignSelf: 'center' }}>
                                {supplier.supplier}
                              </Typography>
                              <Typography sx={{ alignSelf: 'center' }} variant="body1">
                                ({teamLicense.industryGroup})
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{ fontWeight: 'bold', alignSelf: 'center', marginRight: '.5rem' }}
                            >
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(supplier.spendSummary.totalSpend)}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TeamAppReviewForm
                              appCost={supplier.spendSummary.totalSpend}
                              appMonthlyCost={supplier.spendSummary.monthly}
                              sx={{ padding: '1rem' }}
                              complete={supplier.isComplete}
                              saveHandler={(v) => handleSave(v)}
                              addNotes={supplier.presidentNotes}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Form>
                    )}
                  </Formik>
                );
              })}
            </TeamLicensesTable>
          ))
        )}
      </Paper>
    </Container>
  );
};

export default TeamLicenses;
