import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" mt="10em">
    <CircularProgress size="5em" />
  </Box>
);

export default Loading;
