import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';

const TeamAppReviewForm = ({
  appCost,
  appMonthlyCost,
  saveHandler,
  president,
  sendNote,
  complete,
  addNotes,
  ...props
}) => {
  const { values, touched, setFieldValue, setFieldTouched } = useFormikContext();

  const handleSelect = (event) => {
    setFieldValue(`values.actionId`, event.target.value, false);
    setFieldTouched(`touched.actionId`, true);
  };

  const handleReason = (event) => {
    setFieldValue(`values.teamLeadNotes`, event.target.value, false);
    setFieldTouched(`touched.teamLeadNotes`, true);
  };

  const handlePresidentReason = (event) => {
    setFieldValue(`values.presidentNotes`, event.target.value, false);
    setFieldTouched(`touched.presidentNotes`, true);
  };

  const handleSave = () => {
    saveHandler(values);
    setFieldTouched(`touched.actionId`, false);
    setFieldTouched(`touched.teamLeadNotes`, false);
  };

  const handlePresidentNotes = () => {
    sendNote(values);
  };

  const dollarFormat = (num) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
  };

  const { showAlert, teamLeadNotes, presidentNotes } = values.values;

  return (
    <Box {...props}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            sx={{
              width: '100%',
              marginBottom: '1rem',
            }}
            size="small"
            value={values.values.actionId}
            select
            required
            label="Options"
            name="actionId"
            disabled={president || showAlert || complete}
            inputProps={{ 'aria-label': 'Options' }}
            onChange={handleSelect}
          >
            <MenuItem value={1}>Keep As Is</MenuItem>
            <MenuItem value={2}>Expect Usage Increase</MenuItem>
            <MenuItem value={3}>Expect Usage Decrease</MenuItem>
            <MenuItem value={4}>Remove</MenuItem>
            <MenuItem value={5}>Uncertain / Need Assistance</MenuItem>
          </TextField>
          <TableContainer sx={{ marginBottom: '1rem' }}>
            <Table>
              <TableBody>
                {appMonthlyCost.map((item) => (
                  <TableRow key={item.monthOf}>
                    <TableCell sx={{ padding: 0 }} component="th">
                      {format(new Date(item.monthOf), 'MMM, yyyy')}
                    </TableCell>
                    <TableCell sx={{ padding: 0 }} align="right">
                      {dollarFormat(item.charge)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ backgroundColor: `${appCost === 0 ? '#e5f6fd' : 'none'}` }}>
                  <TableCell sx={{ padding: 0 }} component="th">
                    Total:
                  </TableCell>
                  <TableCell sx={{ padding: 0 }} align="right">
                    {dollarFormat(appCost)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={8}>
          <TextField
            sx={{
              width: '100%',
            }}
            size="small"
            label="Reason"
            name="teamLeadNotes"
            variant="outlined"
            type="text"
            required
            multiline
            minRows={5}
            inputProps={{ 'aria-label': 'Reason', readOnly: president || showAlert || complete }}
            value={teamLeadNotes}
            onChange={handleReason}
          />
        </Grid>
      </Grid>
      {president ? (
        <>
          {appCost === 0 && (
            <Alert severity="info">
              This means the teamlead reached out to accounting for the true total
            </Alert>
          )}
          <Stack spacing={2} sx={{ alignItems: 'flex-end', margin: '1rem 0' }}>
            <TextField
              sx={{
                width: '100%',
              }}
              size="small"
              label="Notes"
              name="presidentNotes"
              variant="outlined"
              type="text"
              multiline
              minRows={5}
              inputProps={{ 'aria-label': 'Notes', minLength: 30, readOnly: !complete }}
              helperText="You may enter a note for the tech lead if you'd like for them to make changes to the application consumption answer."
              required
              onChange={handlePresidentReason}
              value={presidentNotes}
            />
            <Button
              sx={{ maxWidth: 'max-content' }}
              variant="outlined"
              disabled={!complete || !touched.touched?.presidentNotes}
              onClick={handlePresidentNotes}
            >
              Send Note to Tech Lead
            </Button>
          </Stack>
        </>
      ) : (
        <>
          {appCost === 0 && (
            <Alert severity="info" sx={{ marginBottom: '1rem' }}>
              This means you will need to reach out to accounting to get the true total
            </Alert>
          )}
          {(showAlert || complete) && (
            <Alert severity="success">This data has been sent to the president for review!</Alert>
          )}
          {addNotes.length > 0 && (
            <Stack
              spacing={2}
              sx={{
                borderRadius: '1rem',
                margin: '1rem auto',
                backgroundColor: '#F5F5F5',
                padding: '1rem',
              }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                Notes from president:
              </Typography>
              <Typography variant="body2">{addNotes}</Typography>
            </Stack>
          )}
          <Stack sx={{ margin: '1rem 0' }} direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={handleSave}
              disabled={
                showAlert ||
                complete ||
                !(touched.touched?.actionId || touched.touched?.teamLeadNotes)
              }
            >
              Save
            </Button>
            <Button type="submit" variant="outlined" disabled={showAlert || complete}>
              Submit
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

TeamAppReviewForm.displayName = 'TeamAppReviewForm';

TeamAppReviewForm.defaultProps = {
  president: false,
  addNotes: '',
  // TODO: remove these - we should refactor to make these rquired
  sendNote: () => {},
  saveHandler: () => {},
};

TeamAppReviewForm.propTypes = {
  appCost: PropTypes.number.isRequired,
  appMonthlyCost: PropTypes.arrayOf(
    PropTypes.shape({ monthOf: PropTypes.string, charge: PropTypes.number })
  ).isRequired,
  saveHandler: PropTypes.func,
  president: PropTypes.bool,
  sendNote: PropTypes.func,
  complete: PropTypes.bool.isRequired,
  addNotes: PropTypes.string,
};

export default TeamAppReviewForm;
