import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';

const TeamLicensesTable = ({ label, children, monthlyCharges, total, industry, ...props }) => {
  const dollarFormat = (num) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ padding: '1rem', alignItems: 'center', borderBottom: '1px solid #000000' }}
        {...props}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h6">
          {industry} - {label}
        </Typography>
      </Stack>
      <TableContainer sx={{ borderBottom: '1px solid #000000' }}>
        <Table sx={{ maxWidth: '500px' }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Quarterly Summary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {monthlyCharges?.map((item) => (
              <TableRow key={item.monthOf}>
                <TableCell component="th">{format(new Date(item.monthOf), 'MMM, yyyy')}:</TableCell>
                <TableCell align="left">{dollarFormat(item.charge)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Total:</TableCell>
              <TableCell>{dollarFormat(total)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {children}
    </>
  );
};

TeamLicensesTable.defaultProps = {
  monthlyCharges: null,
  children: null,
};

TeamLicensesTable.propTypes = {
  label: PropTypes.string.isRequired,
  monthlyCharges: PropTypes.arrayOf(
    PropTypes.shape({ monthOf: PropTypes.string, charge: PropTypes.number })
  ),
  children: PropTypes.node,
  total: PropTypes.number.isRequired,
  industry: PropTypes.string.isRequired,
};

export default TeamLicensesTable;
