import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Card, CardContent, TextField, Stack } from '@mui/material';

const ReportSelect = ({
  allCycles,
  allManagers,
  allVerticals,
  handleCycleChange,
  handleManagerChange,
  handleVerticalChange,
}) => {
  return (
    <Card sx={{ minWidth: '300px' }}>
      <CardContent>
        <Stack spacing={2}>
          <Autocomplete
            id="vertical-select"
            options={allVerticals.verticals}
            isOptionEqualToValue={(option, value) => option.verticalId === value.verticalId}
            defaultValue={allVerticals.verticals.find(
              (v) => v.verticalId === allVerticals.defaultOption
            )}
            onChange={handleVerticalChange}
            getOptionLabel={(option) => option.verticalName}
            renderInput={(params) => <TextField {...params} label="Vertical" />}
            disableClearable
          />

          <Autocomplete
            id="cycle-select"
            options={allCycles.cycles}
            isOptionEqualToValue={(option, value) => option.cycleId === value.cycleId}
            defaultValue={allCycles.cycles.find(
              (c) => c.cycleId === Number(allCycles.defaultOption)
            )}
            onChange={handleCycleChange}
            getOptionLabel={(option) => option.cycleName}
            renderInput={(params) => <TextField {...params} label="Cycle" />}
            disableClearable
          />

          <Autocomplete
            id="managers-select"
            options={allManagers.managers.sort((a, b) => a.label.localeCompare(b.label))}
            isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
            defaultValue={allManagers.managers.find(
              (m) => m.employeeId === allManagers.defaultOption
            )}
            onChange={handleManagerChange}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Manager" />}
            clearOnBlur
            clearOnEscape
            openOnFocus
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

const cycleShape = PropTypes.shape({
  cycleId: PropTypes.number,
  cycleName: PropTypes.string,
  cycleStatus: PropTypes.string,
  startData: PropTypes.string,
});
const allCyclesShape = {
  defaultOption: PropTypes.string,
  cycles: PropTypes.arrayOf(cycleShape),
};

const managerShape = PropTypes.shape({
  employeeId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});
const allManagersShape = {
  defaultOption: PropTypes.string,
  managers: PropTypes.arrayOf(managerShape),
};

const verticalShape = PropTypes.shape({
  verticalId: PropTypes.string,
  verticalName: PropTypes.string,
});
const allVerticalsShape = {
  defaultOption: PropTypes.string,
  verticals: PropTypes.arrayOf(verticalShape),
};

ReportSelect.propTypes = {
  allCycles: PropTypes.shape(allCyclesShape).isRequired,
  allManagers: PropTypes.shape(allManagersShape).isRequired,
  allVerticals: PropTypes.shape(allVerticalsShape).isRequired,
  handleCycleChange: PropTypes.func.isRequired,
  handleManagerChange: PropTypes.func.isRequired,
  handleVerticalChange: PropTypes.func.isRequired,
};

export default ReportSelect;
