import React from 'react';
import { PropTypes } from 'prop-types';
import { Alert, AlertTitle, Box, Link } from '@mui/material';
import { useUser } from '../providers/UserProvider';

const Error = (props) => {
  const { message } = props;
  const { userAccess } = useUser();
  const { vertical } = userAccess;

  return (
    <Box m={5}>
      <Alert severity="error">
        <AlertTitle>Oh No!</AlertTitle>
        <p>{message}</p>
        <p>
          Please reach out in the
          <Link
            href={
              vertical === 'Health'
                ? 'https://rvohealth.slack.com/archives/C04G9TF9A3S'
                : 'https://redventures.slack.com/archives/C047RK99TFT'
            }
            underline="hover"
          >
            {` #sw-license-review `}
          </Link>
          channel, if you continue to experience issues.
        </p>
      </Alert>
    </Box>
  );
};

Error.defaultProps = {
  message: "Don't Panic!",
};

Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
