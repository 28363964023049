import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Container,
  Chip,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Formik, Form } from 'formik';
import fileDownload from 'js-file-download';
import { useAPI } from '../providers/APIProvider';
import TeamLicensesTable from '../components/TeamLicensesTable';
import TeamAppReviewForm from '../components/TeamAppReviewForm';
import Loading from '../components/Loading';

const Reviews = () => {
  // Update variables to correct endpoint if president view gets different one
  const { getTeamLicenses, getConsumptionDownload, sendPresidentNotes, sendPresidentConfirmation } =
    useAPI();
  const [teamLicenses, setTeamLicences] = useState([]);
  const [isOpen, setIsOpen] = useState({});
  const [shouldReload, setShouldReload] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [presidentAlert, setPresidentAlert] = useState(false);

  useEffect(() => {
    const initData = async () => {
      const { industryGroups } = await getTeamLicenses();
      setTeamLicences(industryGroups);
      setIsLoading(false);

      if (shouldReload === null) {
        industryGroups.forEach((vertical) =>
          vertical.spendCategories?.forEach((sc) =>
            sc.suppliers?.forEach((supplier) =>
              setIsOpen((prevState) => {
                return {
                  ...prevState,
                  [supplier.consumptionChargeId]: supplier.isComplete,
                };
              })
            )
          )
        );
        setShouldReload(true);
      }
    };
    if (shouldReload || shouldReload === null) initData();
    setShouldReload(false);
  }, [getTeamLicenses, shouldReload]);

  if (isLoading) {
    return <Loading />;
  }

  const downloadReportData = async () => {
    const data = await getConsumptionDownload();
    fileDownload(data, 'license-data.csv');
  };

  const handleChange = (id) => {
    setIsOpen((prevState) => {
      return {
        ...prevState,
        [id]: !prevState[id],
      };
    });
  };

  const defaultValues = (supplier) => {
    return {
      allocationId: supplier.consumptionChargeId,
      actionId: supplier.actionId == null ? '' : supplier.actionId,
      teamLeadNotes: supplier.itLeadNotes,
      presidentNotes: supplier.presidentNotes,
    };
  };

  // Submission details go here
  const sendSubmission = async () => {
    const response = await sendPresidentConfirmation();
    if (response) {
      console.log('Sent President confirmation');
      setPresidentAlert({
        severity: 'success',
        message: 'Successfully submitted review',
      });
    } else {
      setPresidentAlert({
        severity: 'error',
        message: 'Sorry!  Unable to submit review.',
      });
    }
  };

  const sendToTechLead = async (values) => {
    const response = await sendPresidentNotes(values);
    if (response) {
      console.log('Notes sent to tech lead');
    }

    setShouldReload(true);
  };

  return (
    <Container maxWidth="lg">
      <Typography sx={{ marginTop: '6rem' }} variant="body1">
        Below you will find the Direct Spend (via Workday) and Consumption Based (via Apptio) SaaS
        costs associated with your industry group for the last quarter. For each contract listed
        below, the Tech Leader within your industry has reviewed the spend and made a prediction of
        future usage. Please review their feedback and submit your final approval. Please use the
        notes section if you require further clarification on any recommendation. These notes will
        be sent back to the Tech Lead within your industry.
      </Typography>
      <Typography sx={{ margin: '2rem auto' }} variant="body1">
        If you have any questions, feel free to reach out to{' '}
        <Link href="https://redventures.slack.com/archives/C04F99DA6NL">
          #procurement-saas-support
        </Link>
        .
      </Typography>
      <Paper elevate={4} sx={{ margin: '6rem auto', padding: '2rem' }}>
        <Button variant="contained" onClick={downloadReportData}>
          Download Data
        </Button>
        {teamLicenses?.map((teamLicense) =>
          teamLicense.spendCategories?.map((spendCategory) => (
            <TeamLicensesTable
              key={spendCategory.chargeType}
              label={spendCategory.chargeType}
              monthlyCharges={spendCategory.spendSummary.monthly}
              total={spendCategory.spendSummary.totalSpend}
              industry={teamLicense.industryGroup}
            >
              {spendCategory.suppliers?.map((supplier) => {
                return (
                  <Formik
                    key={supplier.consumptionChargeId}
                    initialValues={{
                      values: defaultValues(supplier),
                    }}
                    onSubmit={sendSubmission}
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <Accordion
                          key={supplier.consumptionChargeId}
                          sx={{ margin: '1rem' }}
                          expanded={isOpen[supplier.consumptionChargeId]}
                          onChange={() => handleChange(supplier.consumptionChargeId)}
                        >
                          <AccordionSummary
                            sx={{
                              backgroundColor: '#FAFAFA',
                              '& .MuiAccordionSummary-content': { justifyContent: 'space-between' },
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${supplier.consumptionChargeId}-content`}
                            id={`panel${supplier.consumptionChargeId}-header`}
                          >
                            <Stack direction="row" spacing={2}>
                              {!supplier.isComplete && (
                                <Chip
                                  sx={{ borderRadius: '.5rem' }}
                                  label="Tech lead review in progress"
                                  color="info"
                                />
                              )}
                              <Typography sx={{ fontWeight: 'bold', alignSelf: 'center' }}>
                                {supplier.supplier}
                              </Typography>
                              <Typography sx={{ alignSelf: 'center' }} variant="body1">
                                ({teamLicense.industryGroup})
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{ fontWeight: 'bold', alignSelf: 'center', marginRight: '.5rem' }}
                            >
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(supplier.spendSummary.totalSpend)}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TeamAppReviewForm
                              appCost={supplier.spendSummary.totalSpend}
                              appMonthlyCost={supplier.spendSummary.monthly}
                              sx={{ padding: '1rem' }}
                              complete={supplier.isComplete}
                              sendNote={(v) => sendToTechLead(v)}
                              addNotes=""
                              president
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Form>
                    )}
                  </Formik>
                );
              })}
            </TeamLicensesTable>
          ))
        )}
        <Button
          sx={{ width: '100%', margin: '1rem 0' }}
          variant="contained"
          onClick={sendSubmission}
          disabled={teamLicenses.some((v) => v.pendingTechLeadReview > 0)}
        >
          I have reviewed all of these
        </Button>
        {presidentAlert && (
          <Alert severity={presidentAlert.severity}>{presidentAlert.message}</Alert>
        )}
      </Paper>
    </Container>
  );
};

export default Reviews;
